@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --primary-color: #1accd9;
  --secodary-hover: #6c757d;
  --secondary-hover-btn: #1accd9;
  --primary-bg-color: #faf7f4;
  --primary-text-color: #000;
  --secondary-text-color: #000;
  --heading-text-color: #98229b;
  --input-label-color: #df9700;
  --icon-bg-color:#ffc107;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

/* =============commo btn=============== */
.common__btn {
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  line-height: 24px;
  background-color: transparent;
  color: var(--secondary-text-color);
  border: 1px solid var(--primary-color);
  font-weight: 500;
  border-radius: 2.25rem;
  transition: 0.6 ease-in-out;
  padding: 10px 20px;
  width: 50%;
}

.common__btn:hover {
  color: var(--primary-bg-color) !important;
  background-color: var(--secondary-hover-btn);
  transition: 0.6s all;
}

.second_button {
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  line-height: 24px;
  background-color: transparent;
  color: var(--secondary-text-color);
  border: 1px solid var(--primary-color);
  font-weight: 500;
  border-radius: 2.25rem;
  transition: 0.6 ease-in-out;
  padding: 10px 20px;
}

.second_button:hover {
  color: var(--primary-bg-color) !important;
  background-color: var(--secondary-hover-btn);
  transition: 0.6s all;
}

.heading__text__color {
  color: var(--heading-text-color) !important;
}

.subheading__text__color {
  color: var(--secondary-text-color) !important;
}

/* .btn.focus,
.btn:focus {
  outline: 0;
  color: var(--primary-bg-color) !important;
  box-shadow: 0 0 0 0.2rem rgb(136, 14, 79, 0.45) !important;
} */

/* Global heading sizes */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

.h1,
h1 {
  font-size: 45px;
  font-weight: 600;
}

.h2,
h2 {
  font-size: 38px;
  /* 2rem */
  font-weight: 600;
}

.h3,
h3 {
  font-size: 30px;
  /* 1.75rem */
  font-weight: 500;
}

.h4,
h4 {
  font-size: 24px;
  /* 1.5rem */
  font-weight: 500;
}

.h5,
h5 {
  font-size: 20px;
  /* 1.25rem */
  font-weight: 500;
}

.h6,
h6 {
  font-size: 16px;
  /* 1rem */
  font-weight: 400;
}

.gallery__section h2,
.gallery__section p {
  color: var(--primary-bg-color);
}

/* smooth scrolling */
html {
  scroll-behavior: smooth;
  /* Enables smooth scrolling */
}

/* ========================================================================== */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  text-decoration: none !important;
}

/* icon */
.bg__properties__color{
  background-color: #eaf7f4;
}

.icon__container__properties {
  font-size: 20px;
  width: 50px;
  height: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(232, 233, 241);
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 18px 0 rgba(188, 192, 202, 0.26);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: var(--icon-bg-color);
  transition: 0.3s;
}

.icon__container__properties:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
  background-color: var( --icon-bg-color);
}

.icon__container__properties:hover .icon__center__properties {
  color: white;
}

.items__subheading__properties p {
  text-align: justify;
}

.property__new__items{
  border-radius: 0px !important;
  padding: 10px !important;
}